a.navbar-brand > img:first-of-type {
  width: 40px;
}

a.navbar-brand > img:nth-of-type(2) {
  height: 40px;
}

.horizontal-menu .header-navbar.navbar-brand-center .navbar-header {
  left: 50%;
  transform: translateX(-50%);
}

.override_justify_right {
  margin-left: auto;
  margin-right: 1.5rem;
}

.in_aresult {
  float: right;
  width: 20px;
  height: 20px;
}

.content-header-title.float-start.mb-0.breadcrumb_less {
  border-right: none;
}

a.brand-logo img:first-of-type, a.brand-logo img:nth-of-type(2) {
  height: 40px;
}

table > tbody > tr > td > span.table_sf {
  font-size: 12px;
}

.table-hover tbody tr {
  cursor: default;
}

.tooltip_div {
  display: inline-block;
}

.btn-group.shop_select > .dropdown-menu > a:last-of-type {
  color: #fff;
  background: #7367f0;
}

.btn-group.shop_select > .dropdown-menu > a:last-of-type:hover {
  background-color: #887ef2;
}

li.list-group-item.title_button, .dd_add_new {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

li.list-group-item.title_button h4 {
  margin-bottom: -2px;
}

.no-p-b {
  padding-bottom: 0;
}

.mt-24 {
  margin-top: 24px !important;
}

/*# sourceMappingURL=index.d49e03de.css.map */
